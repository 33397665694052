.prescription-step {

  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.55);
  }

  &__header {
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.34);
    background-color: white;
    padding: 35px 0;

    @media all and (max-width: 1100px) {
      padding: 20px 0;
    }

    &__title {
      font-family: Poppins, sans-serif;
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      color: #757575;

      @media all and (max-width: 1100px) {
        font-size: 25px;
      }
    }
  }

  &__top-bar {
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    width: 100%;
    min-height: 83px;

    &__go-back {
      cursor: pointer;

      &__img {
        width: 10px;
        margin-left: 15px;
      }
    }

    &__fix-style {
      margin-right: -20px;
    }

    &__text {
      margin: 0 auto;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
    }

  }

  &__progress {
    padding: 50px 0 0;

    @media all and (max-width: 1100px) {
      padding: 30px 15px;
    }

    &__inner {
      width: 650px;
      margin: auto;

      @media all and (max-width: 1100px) {
        width: 100%;
      }

      &__title {
        font-family: Poppins, sans-serif;
        font-weight: bold;
        text-align: center;
        font-size: 35px;

        @media all and (max-width: 1100px) {
          font-size: 20px;
        }
      }

      &__bar {
        width: 100%;
        height: 8px;
        border-radius: 50px;
        overflow: hidden;
        background-color: #F6F6F6;
        margin-top: 30px;

        &__progress {
          height: 100%;
          border-radius: 50px;
        }
      }
    }
  }

  &__update-rg {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: auto;

    &__title {
      text-align: center;
    }

    &__description {
      text-align: center;
    }

    @media screen and (min-width: 768px) {
      width: 650px;
    }

    &__form {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      @media screen and (min-width: 768px) {
        width: max-content;
        flex-direction: row;
      }
    }
  }

  &__single {
    width: 650px;
    margin: auto;

    @media all and (max-width: 1100px) {
      width: 100%;
      padding: 0 15px;
    }

    &__title {
      font-family: Montserrat;
      font-size: 18px;
      color: #000;
      font-weight: normal;
      padding: 30px 0 20px;
    }

    &__message {
      background: #FFE1E1;
      border-radius: 20px;
      padding: 20px 45px 24px 22px;
      font-size: 18px;
      font-family: 'Montserrat';
      font-weight: 400;
      line-height: 28px;
    }

    &__message-attention {
      background: #F7C83F;
      border-radius: 20px;
      padding: 20px 45px 24px 22px;
      font-size: 18px;
      font-family: 'Montserrat';
      font-weight: 400;
      line-height: 28px;
    }

    &__answers {

      &--full {
        width: 100%;
        grid-column: 1 / -1;
      }

      &--inline {
        display: grid;
        grid-template-areas: ". .";
        grid-auto-rows: auto;
        grid-column-gap: 15px;
        grid-template-columns: repeat(2, 1fr);

        .prescription-step__single__answers__single--single-choice__inner {
          text-align: center;
          padding: 23px 15px;
        }
      }

      &__picture {
        margin-top: 15px;
        margin-bottom: 30px;

        &__title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          ;
          padding: 50px 0 80px;

          @media all and (max-width: 1100px) {
            padding-top: 15px;
          }
        }

        .ant-upload-list.ant-upload-list-picture-card {
          display: flex;
          flex-direction: column;

          .ant-upload-list-picture-card-container {
            width: 240px;
            height: 240px;
            margin: auto;

            .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
              border: 1px solid #b5b5b5;
              border-radius: 15px;
              padding: 30px;
            }
          }
        }

        &__container {
          display: flex;
          flex-direction: column;

          &__button-cam {
            display: flex;
          }

          & video {
            margin: auto;
            width: 240px;
            border-radius: 15px;
            height: 240px;
          }

          .ant-upload-picture-card-wrapper {
            width: auto;

            .ant-upload-select-picture-card {
              margin: auto;
              line-height: 1.5715;
              position: relative;
              font-weight: 400;
              white-space: nowrap;
              text-align: center;
              background-image: none;
              border: 1px solid transparent;
              cursor: pointer;
              -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              -ms-touch-action: manipulation;
              touch-action: manipulation;
              height: 32px;
              padding: 4px 15px;
              margin-top: 10px;
              margin-bottom: 0;
              font-size: 14px;
              border-radius: 2px;
              color: rgba(0, 0, 0, 0.85);
              background: #fff;
              border-color: #d9d9d9;
              width: 350px;

              &:focus,
              &:hover,
              &:hover span {
                border-color: #40a9ff;
                color: #40a9ff;
              }
            }
          }

          .ant-upload-list-picture-card-container {
            margin: 0;
          }

          .ant-btn.capture-cam {
            margin: auto;
            width: 240px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 35px;
            border-radius: 15px;
            margin-top: 20px;
            color: #626262;
            font-family: Montserrat;
            font-weight: 600;
          }
        }

        &--hair-loss {

          &__title {
            width: 100%;
            padding-top: 23px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }

          &__container {
            margin-top: 60px;
            margin-left: -50px;
            margin-right: -50px;
            display: flex;
            justify-content: space-between;

            @media all and (max-width: 900px) {
              flex-direction: column;
            }

            &__item {
              display: flex;
              flex-direction: column;
              align-items: center;

              & video {
                width: 175px !important;
                height: 200px !important;
                border-radius: 25px;
              }

              .ant-upload-list {
                display: flex;
                justify-content: center;

                @media all and (max-width: 900px) {
                  position: relative;
                  align-items: center;
                  flex-direction: column;
                }
              }

              .ant-upload-list-picture-card-container,
              .ant-upload-list-item-info {
                width: 175px;
                height: 200px;
                border-radius: 25px;
              }

              .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
                border: none;
                padding: 0;
                border-radius: 25px;
              }
            }

            &__label {
              margin-top: 10px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              color: #000000;
            }

            &__button-cam {
              margin-top: 35px;
              display: flex;
              flex-direction: column;

              @media all and (max-width: 900px) {
                margin-bottom: 35px;
              }

              .ant-btn.capture-cam {
                width: 175px;
                height: 35px;
                border: 1px solid #626262;
                border-radius: 20px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                color: #0C1931;
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      &__single {

        &--single-choice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 25px;
          border-radius: 15px;
          border: 1px solid;
          cursor: pointer;
          margin-bottom: 15px;
          transition: all 200ms ease-in-out;
          user-select: none;

          &__inner {
            font-family: Montserrat;
            color: #000;
          }

          &__icon {
            margin-top: -15px;
            margin-bottom: -15px;
            overflow: visible;

            &__img {
              height: 65px;
            }
          }
        }

        &--open-text {
          padding-bottom: 15px;

          &__inner {

            &--first {
              padding-top: 0;
            }

            &__title {
              font-family: Montserrat;
              color: #000;
              font-size: 16px;
              padding-bottom: 15px;
            }

            &__textarea {
              font-family: Montserrat;
              font-size: 16px;
              width: 100%;
              border-radius: 15px;
              padding: 15px;
              min-width: 100px;
              border: 1px solid;
              min-height: 118px;
              outline: none;
              resize: none;

              &::placeholder {
                color: #000;
              }
            }
          }
        }

        &--multiple-choice {
          padding: 25px;
          border-radius: 15px;
          border: 1px solid;
          cursor: pointer;
          margin-bottom: 15px;
          transition: all 200ms ease-in-out;

          &__inner {
            display: flex;
            flex-direction: row;
            position: relative;

            &__radio {
              width: 21px;
              height: 21px;
              border: 1px solid;
              border-radius: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;

              &__inner {
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border-radius: 50%;
              }
            }

            &__value {
              font-family: Roboto, sans-serif;
              color: #757575;
              user-select: none;
              margin-left: 35px;
            }
          }
        }
      }

      &__birth-date {
        width: 350px;
        margin: auto;
        margin-top: 15px;
      }
    }
  }

  &__footer {
    padding: 15px 0 50px;
    width: 650px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media all and (max-width: 1100px) {
      width: 95%;
      margin: 0 -5px;
    }

    &--end {
      justify-content: center;

    }
    &__button {
      background: #7D7D7D;
      border-radius: 50%;
      outline: none;
      border: none;
      width: 60px;
      height: 60px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;

      &--disabled {
        cursor: default;
        background: #cacaca;
        width: 41px;
        height: 41px;
      }

      &--back {
        background-color: #FFF;
        border: 1px solid #7D7D7D;
        margin-right: 5px;
      }

      &__icon {

        &__img {
          margin-left: 4px;

          &--back {
            margin-left: 0;
            margin-right: 0px;
          }
        }
      }
    }
  }

  &__end {
    background-color: #FFF;
    position: absolute;
    width: 100%;
    height: 95%;
    top: 100px;
    display: flex;
    flex-direction: column;
    padding: 0 28%;

    @media all and (max-width: 800px) {
      padding: 0 15%;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__img {
      width: 60px;
      height: 60px;
      margin: 100px auto 40px;
      margin-bottom: 40px;
    }

    &__text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #000000;
      margin-bottom: 50px;
    }

    &__link {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-decoration-line: underline;
      color: #626262;

      &:hover {
        text-decoration-line: underline;
        color: #626262;
      }
    }
  }
}

.attention-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.attention-icon{
  width: 100px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  display: none;
}